import React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ImportExample,
	Code,
	ExampleProps,
	Header,
} from "../../../../../src/docs";
import { HeaderSection, ContentSection } from "../../../../../src/docs/sections";
import { Paragraph } from "../../text/paragraph/paragraph";
import { TextContainer } from "../../text/text-container/text-container";
import { Ul } from "../../text/list/list";
import { Content } from "../content/content";
import { Grid } from "../grid/grid";
import { StructureExampleToggle } from "../common";
import { H3 } from "../../text/heading/heading";
import { Card } from "./card";

export const Meta: DocPageMeta = {
	category: "Structure",
	title: "Card",
	notepad: "https://hackmd.io/lt0rfMUTTMGd0EVtdhtk_w",
};

/** @ignore */
const StructureExample = (props: ExampleProps) => (
	<Example {...props} customBar={({ id }) => <StructureExampleToggle id={id} />} />
);

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Card"
			subTitle="Use this component to group information, controls and actions about a single topic."
		/>
		<ContentSection>
			<TextContainer article>
				<ImportExample component={Card} />
				<InlineMessage variant="warning">
					<Paragraph>
						Our structure components are strongly interconnected. Make sure to read the structure
						component <GatsbyLink to="/lib/components/Structure/🧭 Guide">guide</GatsbyLink>, to
						find out which components you need and how to combine them correctly.
					</Paragraph>
				</InlineMessage>
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					The card component is the most fundamental building block of our UI. It is used on just
					about every single page in the platform. It's a flexible and visible container, which
					purpose is to group information, controls and actions about a single topic. This provides
					users with a clear and scannable overview of a page's main sections. The following
					examples showcase the card's default implementation and most important variants.
				</Paragraph>
				<Header.H3>Default</Header.H3>
				<Paragraph>
					Here is an example of the card component in it's most basic form. An empty card wouldn't
					have made sense, since a card's purpose is to group content. In fact, without the
					paragraph, the card in the example would have been invisible.
				</Paragraph>
				<Example fn={defaultExample} />
				<Header.H3>Header</Header.H3>
				<Paragraph>
					Cards should almost always have a header. The rare exception is when a card serves as a
					container for structural or navigational content, that should not be exportable to a
					dashboard or does not contain data. The page-level variant of our{" "}
					<GatsbyLink to="/lib/components/Navigation/Tabs">Tabs</GatsbyLink> component is an example
					of this exception.
				</Paragraph>
				<Paragraph>
					To add a header to a card, simply add a <Code>Card.Header</Code> component as the first
					child, as shown in the example below. If you look at the example's HTML output, you'll see
					that the text inside the <Code>Card.Header</Code> is automatically turned into an{" "}
					<Code>H2</Code> <GatsbyLink to="/lib/components/Text/Heading">heading</GatsbyLink>. You
					can put a different heading, such as an <Code>H3</Code> inside the{" "}
					<Code>Card.Header</Code>. However, this generally only makes sense when a card is placed
					inside another card, which is a rare occurrence.
				</Paragraph>
				<Paragraph>
					The example's HTML output also shows that the card's <Code>aria-labelledby</Code> property
					automatically points to the card header. If you don't add a header to a card, you need to
					provide the card with an aria-label yourself, using either its <Code>aria-label</Code> or{" "}
					<Code>aria-labelledby</Code> property.
				</Paragraph>
				<StructureExample fn={headerExample} />
				<Header.H3>Footer</Header.H3>
				<Paragraph>
					Cards can optionally contain a footer. This section is intended for information and
					actions that relate to the <Code>Card</Code> as a whole. In most cases, that will be the{" "}
					<GatsbyLink to="/lab/components/Actions and controls/Action Bar">Action Bar</GatsbyLink>{" "}
					component, which provides users with a set of actions related to the completion of a task.
				</Paragraph>
				<Paragraph>
					Add a footer by placing a <Code>Card.Footer</Code> component inside a <Code>Card</Code>{" "}
					component as its last child.
				</Paragraph>
				<StructureExample fn={footerExample} />
				<Header.H3>Padding</Header.H3>
				<Paragraph>
					A card does not have any padding by default. Whether you need to add padding depends on
					what you're putting in the card. For instance,{" "}
					<GatsbyLink to="#">(dead link) tables</GatsbyLink> should take up the full width of a
					card, but a <GatsbyLink to="#">(dead link) chart</GatsbyLink>,{" "}
					<GatsbyLink to="#">(dead link)form</GatsbyLink>, or{" "}
					<GatsbyLink to="/lib/components/Text/Text container">text-container</GatsbyLink>, should
					always have padding. You add this padding by using the{" "}
					<GatsbyLink to="/lib/components/Structure/Content">content</GatsbyLink> and{" "}
					<GatsbyLink to="/lib/components/Structure/Content.Layout">layout</GatsbyLink> components
					(see example below).
				</Paragraph>
				<StructureExample fn={paddingExample} />
				<Header.H3>Size</Header.H3>
				<Paragraph>
					A card's height is determined by the height of its content, which can change due to user
					action or input. It's not something you actively set.
				</Paragraph>
				<Paragraph>
					In contrast, a card's width is something you actively set, when you want to deviate from
					its default width of 100%. However, you don't use one of the card's properties for this.
					Instead, you wrap each card in a{" "}
					<GatsbyLink to="/lib/components/Structure/Grid and Grid.Section">grid.section</GatsbyLink>{" "}
					and use this component's <Code>size</Code> property to set the card's size.
				</Paragraph>
				<Paragraph>
					These <Code>Grid.Section</Code> components then need to be wrapped in a{" "}
					<GatsbyLink to="/lib/components/Structure/Grid and Grid.Section">grid</GatsbyLink>{" "}
					component, which lays out the cards in a grid. You can see an example of all this below.
					The <GatsbyLink to="/lib/components/Structure/🧭 Guide"> guide</GatsbyLink> features more
					information about how these structure components can be combined.
				</Paragraph>
				<StructureExample fn={sizeExample} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={Card}
					initialProps={{
						"aria-label": "Card example",
						children: (
							<Content>
								<Paragraph>
									Example 1: A card without a header, containing a paragraph wrapped in a content
									component for padding.
								</Paragraph>
							</Content>
						),
					}}
					exampleValues={{
						children: {
							"Example 1": (
								<Content>
									<Paragraph>
										Example 1: A card without a header, containing a paragraph wrapped in a content
										component for padding.
									</Paragraph>
								</Content>
							),
							"Example 2": (
								<>
									<Card.Header>Example 2</Card.Header>
									<Content>
										<Paragraph>
											A card with a header, containing a paragraph wrapped in a content component
											for padding.
										</Paragraph>
									</Content>
								</>
							),
							"Example 3": (
								<>
									<Card.Header>Example 3</Card.Header>
									<Content.Layout>
										<Content>
											<Paragraph>
												A card with a header, containing an absolutely crazy visual composition.
												There's a paragraph here on the left.
											</Paragraph>
										</Content>
										<Content>
											<Paragraph>
												And there's another one here on the right. This visual sorcery is
												accomplished by using our layout and content components.
											</Paragraph>
										</Content>
									</Content.Layout>
								</>
							),
						},
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Ul
						items={[
							<>
								Always add a header to a card, using the <Code>Card.Header</Code> component. Except
								when the card serves as a container for structural or navigational content, that
								should not be exportable to a dashboard or does not contain data.
							</>,
							<>
								Provide cards without a header with an <Code>aria-label</Code>, using either the
								<Code>aria-label</Code> or <Code>aria-labelledby</Code> property.
							</>,
							<>
								To add padding to a card, use a{" "}
								<GatsbyLink to="/lib/components/Structure/Content">content</GatsbyLink> component.
								It can also be used to control the position and alignment of the card’s contents.
							</>,
							<>
								When you have multiple{" "}
								<GatsbyLink to="/lib/components/Structure/Content">content</GatsbyLink> components
								inside a card, use a{" "}
								<GatsbyLink to="/lib/components/Structure/Content.Layout">
									Content.Layout
								</GatsbyLink>{" "}
								component to control their padding, alignment, and positioning.
							</>,
							<>
								Don’t set an explicit size for a card’s height, let it be implicitely determined by
								the height of its contents.
							</>,
							<>Use a full width card when it’s the only card on the page.</>,
							<>
								Choose a width for a card based on its contents and context (the other cards on the
								page).
							</>,
							<>
								To adjust the width of a card, wrap it in a{" "}
								<GatsbyLink to="#">grid.section</GatsbyLink> and use this component’s{" "}
								<Code>size</Code> property.
							</>,
							<>
								When you have multiple cards on a page, wrap them in a{" "}
								<GatsbyLink to="#">grid</GatsbyLink> component.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Paragraph>
						You need to create sections inside of a card or overlay, such as a{" "}
						<GatsbyLink to="#">modal</GatsbyLink> or <GatsbyLink to="#">sidepanel</GatsbyLink>.
						Instead, create these sections by using the{" "}
						<GatsbyLink to="/lib/components/Structure/Content.Layout">Content.Layout</GatsbyLink>,{" "}
						<GatsbyLink to="/lib/components/Structure/Content">content</GatsbyLink>,{" "}
						<GatsbyLink to="/lib/components/Text/heading">heading</GatsbyLink>, and{" "}
						<GatsbyLink to="/lib/components/Structure/Divider">divider</GatsbyLink> components.
						Also, when a card's contents requires sections, consider whether these shouldn't be
						separate cards instead.
					</Paragraph>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Paragraph>
						The card header should contain a{" "}
						<GatsbyLink to="/lib/components/Text/heading">heading</GatsbyLink> with an appropriate
						level. In most cases, this will be an <Code>{"<Header.h2>"}</Code> level heading.
						Deviating from this level can be appropriate when, for instance, a card is nested inside
						an other card. However, make sure never to skip heading levels. For more information,
						see the heading component's{" "}
						<GatsbyLink to="/lib/components/Text/heading">documentation page</GatsbyLink>.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							<>
								A card should always have a heading. Except when it serves as a container for
								structural or navigational content that isn’t dashboardable.
							</>,
							<>
								A card's heading should briefly describe the contents of the card, or what the user
								can do within it.
							</>,
							<>Try to avoid repeating the page heading in the card heading.</>,
							<>
								If a card is the first step in a workflow, the heading may serve as a prompt or a
								call-to-action.
							</>,
							<>
								A dashboardable card's heading should still make sense when the card is exported to
								a dashboard.
							</>,
							<>
								Make sure to read the writing guidelines of the{" "}
								<GatsbyLink to="/lib/components/Text/heading">Heading</GatsbyLink> component.
							</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const defaultExample = () => (
	<Card aria-label="A card without a header must have an aria-label">
		<Paragraph>Without this paragraph, this card would disappear.</Paragraph>
	</Card>
);

const headerExample = () => (
	<Card>
		<Card.Header>Card header</Card.Header>
		<Content>
			<TextContainer article>
				<Paragraph>Check out this example's HTML output. The text above explains why.</Paragraph>
			</TextContainer>
		</Content>
	</Card>
);

const footerExample = () => (
	<Card>
		<Card.Header>Card header</Card.Header>
		<Content>
			<TextContainer article>
				<Paragraph>Card content</Paragraph>
			</TextContainer>
		</Content>
		<Card.Footer>
			<Content>Card footer</Content>
		</Card.Footer>
	</Card>
);

const paddingExample = () => (
	<Card>
		<Card.Header>Lorem Ipsum</Card.Header>
		<Content.Layout padding="large">
			<Content flex="1 1 auto">
				<TextContainer>
					<H3>What is Lorem Ipsum?</H3>
					<Paragraph>
						Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
						has been the industry's standard dummy text ever since the 1500s, when an unknown
						printer took a galley of type and scrambled it to make a type specimen book. It has
						survived not only five centuries, but also the leap into electronic typesetting,
						remaining essentially unchanged. It was popularised in the 1960s with the release of
						Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
						publishing software like Aldus PageMaker including versions of Lorem Ipsum.
					</Paragraph>
				</TextContainer>
			</Content>
			<Content flex="1 1 auto">
				<TextContainer>
					<H3>Why do we use it?</H3>
					<Paragraph>
						It is a long established fact that a reader will be distracted by the readable content
						of a page when looking at its layout. The point of using Lorem Ipsum is that it has a
						more-or-less normal distribution of letters, as opposed to using 'Content here, content
						here', making it look like readable English. Many desktop publishing packages and web
						page editors now use Lorem Ipsum as their default model text, and a search for 'lorem
						ipsum' will uncover many web sites still in their infancy. Various versions have evolved
						over the years, sometimes by accident, sometimes on purpose (injected humour and the
						like).
					</Paragraph>
				</TextContainer>
			</Content>
		</Content.Layout>
	</Card>
);

const sizeExample = () => (
	<>
		<Grid>
			<Grid.Section>
				<Card>
					<Card.Header>Full (default)</Card.Header>
					<Content>
						<TextContainer article>
							<Paragraph>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur at odio elit.
								Donec laoreet massa justo, at sagittis nulla volutpat vitae. Nullam efficitur
								efficitur justo non egestas.
							</Paragraph>
						</TextContainer>
					</Content>
				</Card>
			</Grid.Section>
			<Grid.Section size="half">
				<Card>
					<Card.Header>Half</Card.Header>
					<Content>
						<TextContainer article>
							<Paragraph>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur at odio elit.
								Donec laoreet massa justo, at sagittis nulla volutpat vitae. Nullam efficitur
								efficitur justo non egestas.
							</Paragraph>
						</TextContainer>
					</Content>
				</Card>
			</Grid.Section>
			<Grid.Section size="fourth">
				<Card>
					<Card.Header>Fourth A</Card.Header>
					<Content>
						<TextContainer article>
							<Paragraph>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur at odio elit.
								Donec laoreet massa justo, at sagittis nulla volutpat vitae. Nullam efficitur
								efficitur justo non egestas.
							</Paragraph>
						</TextContainer>
					</Content>
				</Card>
			</Grid.Section>
			<Grid.Section size="fourth">
				<Card>
					<Card.Header>Fourth B</Card.Header>
					<Content>
						<TextContainer article>
							<Paragraph>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur at odio elit.
								Donec laoreet massa justo, at sagittis nulla volutpat vitae. Nullam efficitur
								efficitur justo non egestas.
							</Paragraph>
						</TextContainer>
					</Content>
				</Card>
			</Grid.Section>
			<Grid.Section size="third">
				<Card>
					<Card.Header>Third</Card.Header>
					<Content>
						<TextContainer article>
							<Paragraph>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur at odio elit.
								Donec laoreet massa justo, at sagittis nulla volutpat vitae. Nullam efficitur
								efficitur justo non egestas.
							</Paragraph>
						</TextContainer>
					</Content>
				</Card>
			</Grid.Section>
			<Grid.Section size="twothirds">
				<Card>
					<Card.Header>Two thirds</Card.Header>
					<Content>
						<TextContainer article>
							<Paragraph>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur at odio elit.
								Donec laoreet massa justo, at sagittis nulla volutpat vitae. Nullam efficitur
								efficitur justo non egestas.
							</Paragraph>
						</TextContainer>
					</Content>
				</Card>
			</Grid.Section>
		</Grid>
	</>
);
